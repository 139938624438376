import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectSistemaMant (Vue, filter, search, sorter, page, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('activar', true)
    if (idsistema) {
      apiFilter.addExact('idsistema', idsistema)
    }
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('tactuacion_descripcion', search)
        .addILike('periodo_revision_descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call('sistemaMant.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },

  async selectSistemaMantRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idsistema_mant', pks)
    const resp = await Vue.$api.call('sistemaMant.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },

  async generarRevision (Vue, idsistemaMant) {
    return await Vue.$api.call('sistemaMant.crearOrdenTrabajoRevision', { idsistema_mant: idsistemaMant })
  }
}
