<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron revisiones"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :load="loadItems"
      :showing-sorter.sync="showingDialogs.sorter"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idsistema_mant"
    >
      <template
        #listItem="slotProps"
      >
        <sistema-mant-list-item
          :item="slotProps.item"
          :has-perm-crear-parte="hasViewPerm(permissions.partes.nuevoParteTrabajo)"
          @click-generar-revision="clickGenerarRevision"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './SistemaMantListData'
import SistemaMantListItem from './components/SistemaMantListItem'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesSistemaMantList'

export default {
  components: {
    SistemaMantListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Revisiones'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectSistemaMant(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idsistema
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectSistemaMantRows(this, dirty.modified), dirty, 'idsistema_mant'
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickGenerarRevision (data) {
      const tituloRevision = `${data.item.tactuacion_descripcion} ${data.item.periodo_revision_descripcion}`
      const res = await this.$alert.showConfirm(
        `¿Deseas generar una orden de trabajo para la revisión "${tituloRevision}"?`
      )
      if (res) {
        this.$loading.showManual(`Creando orden para "${tituloRevision}"...`)
        try {
          const ordenTrabajo = await Data.generarRevision(this, data.item.idsistema_mant)
          // No te pregunta si quieres descargar o asignarte el parte porque puedes tener la configuración de asignación de parte automático.
          const verOtRes = await this.$alert.showConfirm(
            `Se ha creado la orden de trabajo ¿desea visualizarla?`
          )
          if (verOtRes) {
            this.$appRouter.push({
              name: 'ordenes__orden-trabajo-view',
              params: {
                idorden_trabajo: ordenTrabajo.data.result,
              },
            })
          }
        } finally {
          this.$loading.hide()
        }
        this.resetList()
      }
    },
  }
}
</script>
